<template lang="pug">
  section.contacts
    #contacts.blank
    .contacts__container.contacts__container--big
      h3.contacts__title.title Наші контакти
      p.contacts__subtitle Ми раді відповісти на всі ваші питання
      ul.contacts__list
        li.contacts__item
          .contacts__img
            img(
              :src="ContactMail"
              alt="write us"
            )
          p.contacts__text Напишіть нам
          a(href="mailto:vidar.sk.zp@gmail.com") vidar.sk.zp@gmail.com
          a.contacts__link.contacts__link--telegram(href="https://t.me/+xyuYPawnDw1mYmMy") Telegram-чат
        li.contacts__item
          .contacts__img
            img(
              :src="ContactVisit"
              alt="visit us"
            )
          p.contacts__text Завітайте до нас
          p.contacts__adress місто Запоріжжя
          p.contacts__adress вулиця Парамонова 15
        li.contacts__item
          .contacts__img
            img(
              :src="ContactOpen"
              alt="open time"
            )
          p.contacts__text Наші двері відкриті
          p.contacts__time Пн-Пт: з 12:00 до 21:00
          p.contacts__time Сб: з 10:00 до 15:00
        li.contacts__item
          .contacts__img
            img(
              :src="ContactCall"
              alt="call us"
            )
          p.contacts__text Зателефонуйте нам
          a(href="tel:+380687835335") +38 (068) 783-53-35
          a(href="tel:+380507835335") +38 (050) 783-53-35
      iframe.contacts__map(src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2680.9096991781785!2d35.208159099999996!3d47.783198999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc5eec037f5a85%3A0xcc7e3d2f650a12c2!2z0YPQuy4g0J_QsNGA0LDQvNC-0L3QvtCy0LAsIDE1LCDQl9Cw0L_QvtGA0L7QttGM0LUsINCX0LDQv9C-0YDQvtC20YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDY5MDYx!5e0!3m2!1sru!2sua!4v1674568005820!5m2!1sru!2sua")
</template>

<script>
// images
import ContactMail from '@/assets/images/home-page/contacts/01.svg'
import ContactVisit from '@/assets/images/home-page/contacts/02.svg'
import ContactOpen from '@/assets/images/home-page/contacts/03.svg'
import ContactCall from '@/assets/images/home-page/contacts/04.svg'
export default {
  name: 'ContactsBlock',
  data () {
    return {
      ContactMail,
      ContactVisit,
      ContactOpen,
      ContactCall
    }
  }
}
</script>
